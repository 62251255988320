<template>
  <!-- 地址列表 -->
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon
        name="arrow-left"
        @click="step == 1 ? $fun.routerToPage('/user/userDetail') : (step -= 1)"
      />
    </template>
    <template #default>
      <span>
        {{ $fanyi("配送管理") }}
      </span>
    </template>
    <!--设置内容选项区域 内容区域 -->
    <template v-slot:right-text>
      <div class="top-title" @click="switchtitle">
        {{ showdelete == false ? $fanyi("管理") : $fanyi("完成") }}
      </div>
    </template>
  </headBackBar>

  <!-- 内容区域 -->

  <div class="Content" v-if="step == 1">
    <div class="addressitem" v-for="(item, index) in list" :key="item.id">
      <div class="left-check" v-if="showdelete">
        <van-checkbox v-model="item.checked" shape="square"></van-checkbox>
      </div>
      <!-- 用户信息 -->
      <div class="userinfo" @click="compile(item)">
        <div class="title">
          <span class="name">{{ item.name }}</span>
          <span class="mobile">{{ item.mobile }}</span>
        </div>
        <div class="address">
          {{ item.address }}
        </div>
      </div>
      <!--  右侧定位盒子-->
      <div class="right-box" @click="compile(item)">
        <div class="dizhi">
          <!-- {{ $fanyi("地址") + convertToChineseNumeral(index + 1) }} -->
          {{ item.title }}
        </div>
        <div class="banji">
          <img
            src="../../../../assets/user-img/usersetting/modify.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="noData" v-if="!list.length">
      <div>
        <img :src="require('@/assets/icon/dz.png')" alt="" />
        <span>{{ $fanyi("暂无收货地址") }}</span>
      </div>
    </div>
  </div>
  <div class="footer-box">
    <div class="xinzeng" @click="deleted" v-if="showdelete">
      {{ $fanyi("删除") }}
    </div>
    <div class="xinzeng" @click="add" v-else>{{ $fanyi("新增地址") }}</div>
  </div>
</template>
<script setup>
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const store = useStore();
const router = useRouter();
console.log(store);
const step = ref(1);

// 控制删除
const showdelete = ref(false);
const checked = ref(true);
// 收货地址列表
const list = ref([]);
const switchtitle = () => {
  if (list.value.length == 0) {
    Toast(proxy.$fanyi("请先添加地址"));
    return;
  }
  showdelete.value = !showdelete.value;
};
// 处理地址
const convertToChineseNumeral = (num) => {
  if (num == 10) {
    return "十";
  } else if (num == 1) {
    return "一";
  }
  const digits = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  const units = ["", "十", "百", "千", "万"];
  let result = "";
  let numStr = num.toString();
  for (let i = 0; i < numStr.length; i++) {
    let digit = parseInt(numStr.charAt(i));
    let unit = units[numStr.length - i - 1];
    if (digit === 0) {
      // 当前数字为0时不需要输出汉字，但需要考虑上一个数字是否为0，避免出现连续的零
      if (result.charAt(result.length - 1) !== "零") {
        result += "零";
      }
    } else {
      result += digits[digit] + unit;
    }
  }
  // 对于一些特殊的数字，如10、100等，需要在最前面加上“一”
  if (result.charAt(0) === "一") {
    result = result.substr(1, result.length);
  } else if (result.charAt(0) === "百") {
    result = "一" + result;
  } else if (result.charAt(0) === "千") {
    result = "一" + result;
  }
  return result;
};

// 获取地址
Toast.loading({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
});
const addresslist = async () => {
  const res = await proxy.$api.userAddressList();
  if (res.code !== 0) {
    Toast.clear();
    Toast.fail(proxy.$fanyi(res.msg));
    return;
  }
  list.value = res.data.data;

  Toast.clear();
};
addresslist();
// 点击地址每一项，跳转
const compile = (item) => {
  // store.commit('Storinguseraddress', item)

  // router.push({
  //   path: '/user/addressmodification',
  //   query: {
  //     type: 'edit'
  //   }
  // })
  sessionStorage.setItem("Addressuser", JSON.stringify(item));
  proxy.$fun.routerToPage("/user/addressmodification?type=edit");
};
// 新增地址
const add = () => {
  proxy.$fun.routerToPage("/user/addressmodification?type=add");
};
// 删除地址
const deleted = async () => {
  const ids = list.value.reduce((acc, item) => {
    if (item.checked == true) {
      acc.push(item.id);
    }
    return acc;
  }, []);
  Toast.loading({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
  });
  const res = await proxy.$api.userAddressDelete({
    id: ids,
  });
  if (res.code !== 0) {
    Toast.clear();
    Toast.fail(proxy.$fanyi(res.msg));
    return;
  }
  Toast.success(proxy.$fanyi("删除成功"));
  Toast.clear();
  addresslist();
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

.top-title {
  min-width: 55px;
  font-size: 24px;
  white-space: nowrap;
  font-weight: 400;
  color: #000000;
}

.Content {
  width: 750px;
  height: 1400px;
  background: #f6f6f6;
  background: #f6f6f6;
  padding: 0 30px;
  padding-top: 20px;

  :deep() .van-checkbox {
    width: 30px;
    height: 30px;
    background: #ffffff;
    overflow: hidden;

    border: 1px solid #dfdfdf;

    border-radius: 6px;
  }

  :deep() .van-badge__wrapper {
    border: none;
    width: 30px;
    padding-top: 5px;
    font-size: 25px;
    height: 35px;
  }

  .addressitem {
    width: 690px;
    position: relative;
    min-height: 146px;
    padding-left: 30px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    // padding-bottom: 30px;

    .userinfo {
      height: 100%;
      width: 100%;
      padding-top: 36px;

      .title {
        margin-bottom: 17px;

        .name {
          font-size: 28px;

          font-weight: 400;
          color: #000000;
        }

        .mobile {
          margin-left: 40px;
          font-size: 28px;

          font-weight: 400;
          color: #000000;
        }
      }

      .address {
        font-size: 24px;

        font-weight: 400;
        width: 413px;
        line-height: 30px;
        // overflow: hidden;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // white-space: normal;
        color: #999999;
        margin-bottom: 35px;
      }
    }

    .right-box {
      position: absolute;
      right: 0;
      width: 100px;
      height: 100%;

      .dizhi {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 80px;
        max-width: 300px;
        height: 40px;
        background: #eff4ff;
        border-radius: 0px 6px 0px 6px;
        padding: 0 10px;
        font-size: 20px;
        font-weight: 400;
        line-height: 40px;
        color: #000000;
        margin-bottom: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .banji {
        position: absolute;
        bottom: 35px;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .left-check {
      margin-right: 21px;
    }
  }
}

.footer-box {
  width: 750px;
  height: 160px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  padding-top: 25px;

  .xinzeng {
    width: 690px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background: #b4272b;
    font-size: 28px;

    font-weight: 400;
    color: #ffffff;
    border-radius: 6px;
  }
}
.noData {
  padding-top: 181px;
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 294px;
      height: 265px;
      margin-bottom: 44px;
    }
    span {
      font-size: 24px;
      line-height: 36px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
